import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import {
  BumpOfferData,
  ProductData,
  ProductPrice
} from '../../utils/productPrice';

const ProductItemText = props => {
  const {
    text,
    currentCategory,
    dynamicCurrentTubIndex = false,
    currentTubIndex,
    bumpOfferIndex,
    chewsPerTub,
    subSavingsAddon,
    otpSavingsAddon,
    pricesDividedBy,
    savingsDividedBy
  } = props;

  const {
    currency,
    currentTubIndex: currentTubIndexContext,
    extraObjects
  } = useContext(FunnelContext);

  const InnerHTML = () => {
    let currentText = text;
    const _pricesDividedBy =
      pricesDividedBy && pricesDividedBy >= 1 ? pricesDividedBy : 1;
    const _savingsDividedBy =
      savingsDividedBy && savingsDividedBy >= 1 ? savingsDividedBy : 1;
    const _currentCategory = currentCategory ?? 'subscriptions';
    const _currentTubIndex = dynamicCurrentTubIndex
      ? currentTubIndexContext
      : currentTubIndex ?? 0;
    const _bumpOfferIndex = bumpOfferIndex ?? 0;
    const currentProduct = `${
      _currentCategory === 'subscriptions' ? 'subscription' : 'onetime'
    }_${_currentTubIndex ?? 0}`;

    const discountedPrice = ProductPrice({
      type: `discounted_price`,
      currentCategory: _currentCategory,
      currentProduct: currentProduct,
      filterByTag: extraObjects?.filterProductItemsByTag || false
    });
    const price = ProductPrice({
      type: `price`,
      currentCategory: _currentCategory,
      currentProduct: currentProduct,
      filterByTag: extraObjects?.filterProductItemsByTag || false
    });
    let savings = ProductPrice({
      type: `savings`,
      currentCategory: _currentCategory,
      currentProduct: currentProduct,
      filterByTag: extraObjects?.filterProductItemsByTag || false
    });
    const savingsAddon =
      _currentCategory === 'subscriptions' ? subSavingsAddon : otpSavingsAddon;
    const totalSavingsPlusAddon =
      parseFloat(savings) + parseFloat(savingsAddon || 0);
    const savingsPerTub = savings / _savingsDividedBy;
    const savingsPerChew = savings / (chewsPerTub ?? 30);
    const savingsPerDay = savings / 30;
    const savingsPerMonth = savings / _pricesDividedBy;

    const _discountedPrice = discountedPrice / _pricesDividedBy;
    const discountedPricePerDay = _discountedPrice / 30;
    const discountedPricePerChew = _discountedPrice / (chewsPerTub ?? 30);

    const _price = price / _pricesDividedBy;
    const pricePerDay = _price / 30;
    const pricePerChew = _price / (chewsPerTub ?? 30);

    const currencySymbol = currency?.symbol || '$';

    currentText = currentText?.replace(/\[currency-symbol\]/gm, currencySymbol);
    currentText = currentText?.replace(
      /\[price\]/gm,
      parseFloat(_price).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[price-per-day\]/gm,
      parseFloat(pricePerDay).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[price-per-chew\]/gm,
      parseFloat(pricePerChew).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[discounted-price\]/gm,
      parseFloat(_discountedPrice).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[discounted-price-per-day\]/gm,
      parseFloat(discountedPricePerDay).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[discounted-price-per-chew\]/gm,
      parseFloat(discountedPricePerChew).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[savings\]/gm,
      parseFloat(totalSavingsPlusAddon).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[savings-per-tub\]/gm,
      parseFloat(savingsPerTub).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[savings-per-chew\]/gm,
      parseFloat(savingsPerChew).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[savings-per-day\]/gm,
      parseFloat(savingsPerDay).toFixed(2)
    );
    currentText = currentText?.replace(
      /\[savings-per-month\]/gm,
      parseFloat(savingsPerMonth).toFixed(2)
    );

    const [productData] = ProductData({
      currentCategory: _currentCategory,
      currentProduct: currentProduct
    });

    currentText = currentText?.replace(
      /\[quantity\]/gm,
      productData?.checkoutData.quantity
    );
    currentText = currentText?.replace(
      /\[image\]/gm,
      productData?.checkoutData.image
    );
    currentText = currentText?.replace(
      /\[perceived_rrp\]/gm,
      productData?.checkoutData.perceived_rrp
    );
    currentText = currentText?.replace(
      /\[discount_percentage\]/gm,
      productData?.discount_percentage
    );
    currentText = currentText?.replace(
      /\[display_title\]/gm,
      productData?.display_title
    );

    const bumpOfferData = BumpOfferData({
      currentCategory: _currentCategory
    });

    currentText = currentText?.replace(
      /\[bump-discounted-price\]/gm,
      bumpOfferData[_bumpOfferIndex]?.checkoutData.discounted_price.toFixed(2)
    );
    currentText = currentText?.replace(
      /\[bump-price\]/gm,
      bumpOfferData[_bumpOfferIndex]?.checkoutData.price.toFixed(2)
    );
    currentText = currentText?.replace(
      /\[bump-image\]/gm,
      bumpOfferData[_bumpOfferIndex]?.checkoutData.image
    );

    return {
      __html: currentText
    };
  };

  return <div {...props.attributes} dangerouslySetInnerHTML={InnerHTML()} />;
};

export default ProductItemText;
